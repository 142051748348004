import { Key } from 'react'
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form'
import styled from 'styled-components'

import { Checkbox, Radio, Select, TextField } from 'common/UI'
import { ErrorText } from 'common/UI/Field'
import { Upload } from 'common/UI/Upload'
import {
  DynamicFormStoryblok,
  FormCheckboxStoryblok,
  FormFieldGroupStoryblok,
  FormFileStoryblok,
  FormInputStoryblok,
  FormRadioStoryblok,
  FormSelectStoryblok,
  ValidatorMaxStoryblok,
  ValidatorMinStoryblok,
  ValidatorOptionStoryblok,
  ValidatorPatternStoryblok,
  ValidatorRequiredStoryblok,
} from 'common/types'
import { Editable } from 'modules/Blocks/Editable'

import { parseMarkdownLinksToHtml } from './html'

export type Validator =
  | ValidatorRequiredStoryblok
  | ValidatorMinStoryblok
  | ValidatorMaxStoryblok
  | ValidatorPatternStoryblok
  | ValidatorOptionStoryblok
export type FormField =
  | FormInputStoryblok
  | FormSelectStoryblok
  | FormCheckboxStoryblok
  | FormRadioStoryblok
  | FormFileStoryblok
  | FormFieldGroupStoryblok
export type SignedFileResponse = {
  fields: { [key: string]: string }
  post_url: string
  pretty_url: string
  public_url: string
  id: number
}
export type WatchedComponent = { name: string; control: Control<FieldValues> }

export const isSelectField = (
  field?: FormField
): field is FormSelectStoryblok => {
  return field?.component === 'form-select'
}

export const getEmailText = async (
  data: Record<string, string | File[]>,
  fields: DynamicFormStoryblok['fields'],
  aditionalData: Record<string, string> = {}
): Promise<string> => {
  let text = ''

  for (const [key, value] of Object.entries(data)) {
    const field = fields.find((f) => f.name === key)

    // file inputs
    // we need to first upload the file to the server, then get the url and add it to the email text
    if (Array.isArray(value) && value[0] instanceof File) {
      text += `${key}:\n`

      for (const [index, file] of Array.from(value.entries())) {
        // upload the file
        const url = await sendFile(file)

        if (index !== value.length - 1) {
          text += `${url}\n`
        } else {
          text += `${url}\n\n`
        }
      }
    } else {
      let label = ''

      // if we're handling select fields, we should use the option label instead of the value
      if (isSelectField(field)) {
        label = field.options?.find((o) => o.value === value)?.label || ''
      }

      text += `${key}: ${label || value}\n\n`
    }
  }

  // add aditional data
  for (const [key, value] of Object.entries(aditionalData)) {
    text += `${key}: ${value}\n\n`
  }

  return text
}

export const sendEmail = async ({
  to,
  subject,
  message,
  formURL,
  customer,
}: {
  to: string[]
  subject?: string
  message: string
  formURL: string
  customer?: {
    email: string
    name?: string
  }
}): Promise<{ ok: boolean; error?: unknown }> => {
  try {
    const res = await fetch('/api/send-mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        recipients: to,
        subject: subject ? subject : `New form submitted on ${formURL}`,
        content: message,
        customer,
      }),
    })

    return {
      ok: res.status === 200,
    }
  } catch (error: unknown) {
    return {
      ok: false,
      error: 400,
    }
  }
}

export const sendFile = async (file: File): Promise<string> => {
  const space = await fetch(
    `https://api.storyblok.com/v2/cdn/spaces/me?token=${process.env.STORYBLOK_API_PUBLIC_KEY}`
  )
  const {
    space: { id: spaceId },
  } = await space.json()

  const signedResp = await fetch('/api/sign-file', {
    method: 'POST',
    body: JSON.stringify({
      filename: file.name,
      spacesRoot: 'https://mapi.storyblok.com/v1/spaces',
      spaceId,
    }),
  })

  const signedJson = await signedResp.json()

  if (!signedJson?.ok || !signedJson.data || !signedJson.data?.post_url) {
    throw new Error("Couldn't sign file")
  }

  const { fields, post_url } = signedJson.data as SignedFileResponse

  const formData = new FormData()
  for (const key in fields) {
    formData.append(key, fields[key])
  }
  formData.append('file', file)

  const sendResp = await fetch(post_url, {
    method: 'POST',
    body: formData,
  })

  if (sendResp.status !== 204) throw new Error("Couldn't send file")

  return `https://a.storyblok.com/${fields.key}`
}

export const parseWithFormValues = (
  text: string | undefined,
  values: Record<string, string>
): string => {
  if (!text) {
    return ''
  }

  const pattern = /\{\{(.*?)\}\}/gim
  const matches = text.match(pattern)
  const parts = text.split(pattern)

  if (!matches) {
    return text
  }

  const elements = parts.map((part) => values[part] || part)

  return elements.join('')
}

export const getFieldRules = (validators?: Validator[]): RegisterOptions => {
  if (!validators) return {}

  return validators.reduce((acc, currVal) => {
    const { component, error_message } = currVal

    switch (component) {
      case 'validator-required':
        return { ...acc, required: error_message || true }
      case 'validator-pattern': {
        const { pattern } = currVal
        if (!pattern) return { ...acc }

        return {
          ...acc,
          pattern: {
            // TODO: remove regex flag when fix on storyblok is done
            value: new RegExp(pattern, 'i'),
            message: error_message || '',
          },
        }
      }
      case 'validator-min': {
        const { min_length } = currVal
        if (!min_length) return { ...acc }

        return {
          ...acc,
          minLength: {
            value: min_length,
            message: error_message || '',
          },
        }
      }
      case 'validator-max': {
        const { max_length } = currVal
        if (!max_length) return { ...acc }

        return {
          ...acc,
          maxLength: {
            value: max_length,
            message: error_message || '',
          },
        }
      }

      case 'validator-option': {
        const { option } = currVal
        if (!option) return { ...acc }

        return {
          ...acc,
          validate: (v: string) => (v === option ? error_message : null),
        }
      }

      default:
        return { ...acc }
    }
  }, {})
}

export const getFieldComponent = ({
  field,
  getValues,
  errors,
  register,
  control,
}: {
  field: FormField
  getValues: UseFormGetValues<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues>
}): JSX.Element | null => {
  if (field.component === 'form-field-group') {
    return (
      <FieldGroupWrapper>
        {field.fields.map(
          (nestedField: FormField, idx: Key | null | undefined) => (
            <Editable block={field} key={idx}>
              {getFieldComponent({
                field: nestedField,
                getValues,
                errors,
                register,
                control,
              })}
            </Editable>
          )
        )}
      </FieldGroupWrapper>
    )
  }

  if (!field.name) return null

  const fieldRules = getFieldRules(field.validators)
  const fieldName = field.field_name
  const fieldValues = field.field_value && field.field_value.split(',')

  const fieldValue = fieldName && getValues(fieldName)
  const fieldCondition = fieldValues && fieldValues.includes(fieldValue)

  if (fieldName && fieldValues && !fieldCondition) {
    return null
  }

  switch (field.component) {
    case 'form-input':
      return (
        <TextField
          variant={field.variant || 'input'}
          label={field.label || ''}
          placeholder={field.placeholder}
          error={errors[field.name]?.message}
          key={`${field._uid}-${field.name}`}
          {...register(field.name, fieldRules)}
        />
      )
    case 'form-select':
      return (
        <Select
          label={field.label || ''}
          placeholder={field.placeholder}
          error={errors[field.name]?.message}
          key={`${field._uid}-${field.name}`}
          {...register(field.name, fieldRules)}
        >
          {field?.options?.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </Select>
      )
    case 'form-checkbox': {
      return (
        <div css={{ padding: '0.75rem 0' }}>
          <Checkbox
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: parseMarkdownLinksToHtml(field.label),
                }}
              />
            }
            {...register(field.name, fieldRules)}
          />
          {errors[field.name] && (
            <ErrorText as="span" variant="fourteen">
              {errors[field.name].message}
            </ErrorText>
          )}
        </div>
      )
    }
    case 'form-radio':
      return (
        <div css={{ padding: '0.75rem 0' }}>
          <Radio
            label={field.label}
            value={field.value}
            defaultChecked={field.default_checked}
            {...register(field.name, fieldRules)}
          />
        </div>
      )
    case 'form-file':
      return (
        <>
          <Controller
            render={({ field: { name, ref, onChange, value } }) => {
              const acceptedFileTypes = field.accepted_file_types
              return (
                <Upload
                  name={name}
                  label={field.label}
                  placeholder={field.placeholder}
                  acceptedFileTypes={acceptedFileTypes}
                  setFilesCallback={(files) => onChange(files)}
                  value={value}
                  ref={ref}
                />
              )
            }}
            control={control}
            rules={fieldRules}
            name={field.name}
            defaultValue={[]}
          />
          {errors[field.name] && (
            <ErrorText as="span" variant="fourteen">
              {errors[field.name].message}
            </ErrorText>
          )}
        </>
      )
    default:
      return null
  }
}

const FieldGroupWrapper = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;
  gap: 1.5rem;
`
