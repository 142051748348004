import styled from 'styled-components'

import { Container, Text } from 'common/UI'
import { addAlphaToColor, dashedLine } from 'common/utils/style'

export const Section = styled(Container)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }
`

export const Wrapper = styled.div<{ isDrawer: boolean }>`
  width: 100%;

  padding-top: 2rem;
  padding-bottom: 2rem;

  ${({ theme }) => theme.media.md} {
    padding-top: ${({ isDrawer }) => (isDrawer ? '2rem' : '3.75rem')};
    padding-bottom: ${({ isDrawer }) => (isDrawer ? '2rem' : '3.75rem')};
  }
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Form = styled.form`
  flex: 1;
  margin-top: 2rem;

  ${({ theme }) => theme.media.md} {
    margin-top: 0;
  }
`

export const SentBox = styled.div`
  display: flex;
  align-items: start;

  background-color: #078366;
  color: ${({ theme }) => theme.colors.background.default};
  border-radius: 0.5rem;

  padding: 1rem;
  margin-top: 2rem;

  ${({ theme }) => theme.media.md} {
    margin-top: 0;
  }
`

export const ErrorText = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.colors.accent.subtle};
  margin-top: 0.25rem;
`

export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 1rem 0;
  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.default, 20))};
`
