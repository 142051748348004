/**
 * written by copilot :D
 * function that receives a single line of text (string) with markdown links and returns html
 * example:
 * 'I agree with the [terms and conditions](https://somewebsite.com/terms) and [privacy policy](https://somewebsite.com/privacy)'
 * returns:
 * 'I agree with the <a href="https://somewebsite.com/terms">terms and conditions</a> and <a href="https://somewebsite.com/privacy">privacy policy</a>'
 **/
export function parseMarkdownLinksToHtml(line: string): string {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g
  let result = line
  let match
  while ((match = regex.exec(line)) !== null) {
    const url = match[2]
    result = result.replace(
      match[0],
      `<a target="_blank" rel="noopener noreferrer" href="${url}">${match[1]}</a>`
    )
  }

  return result
}
